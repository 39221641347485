"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { STATIC_SEARCH_PARAMS } from "utils/constants";
import { isPublicRoute } from "utils/server";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

export default function SupabaseAuthListener({
  accessToken,
  redirectUrl
}: {
  accessToken?: string;
  redirectUrl?: string;
}) {
  const supabaseClient = useSupabaseBrowser();
  const router = useRouter();
  const [pageUrl, setPageUrl] = useState("");
  const searchParams = useSearchParams();
  const pathname = usePathname();

  useEffect(() => {
    setPageUrl(window.location.href);
  }, [pathname]);

  const supabaseAddedParams = pageUrl ? new URLSearchParams(new URL(pageUrl).hash.slice(1)) : undefined;
  const paramRefreshToken = searchParams?.get("refresh_token") || supabaseAddedParams?.get("refresh_token");
  const paramAccessToken = searchParams?.get("access_token") || supabaseAddedParams?.get("access_token");
  const paramPendingQuizSubmission = searchParams?.get(STATIC_SEARCH_PARAMS.pendingQuizSubmission);

  useEffect(() => {
    if (paramRefreshToken && paramAccessToken) {
      supabaseClient.auth
        .setSession({
          access_token: paramAccessToken,
          refresh_token: paramRefreshToken
        })
        .then(() => {
          if (isPublicRoute(window.location.pathname)) {
            router.replace(window.location.pathname);
          } else if (paramPendingQuizSubmission) {
            return;
          } else {
            router.push(redirectUrl ? redirectUrl : "/table");
          }
        });
    }
  }, [
    searchParams,
    router,
    paramRefreshToken,
    paramAccessToken,
    redirectUrl,
    supabaseClient.auth,
    paramPendingQuizSubmission
  ]);

  useEffect(() => {
    const { data: authListener } = supabaseClient.auth.onAuthStateChange((event, session) => {
      if (
        !session &&
        typeof window !== "undefined" &&
        window.location.pathname !== "/login" &&
        !isPublicRoute(window.location.pathname)
      ) {
        window.location.replace("/login");
        return;
      }
      if (event === "SIGNED_OUT") {
        // delete cookies on sign out
        const expires = new Date(0).toUTCString();
        document.cookie = `sa-access-token=; path=/; expires=${expires}; SameSite=Lax; secure`;
        document.cookie = `sa-refresh-token=; path=/; expires=${expires}; SameSite=Lax; secure`;
      } else if ((event === "SIGNED_IN" || event === "TOKEN_REFRESHED") && session) {
        // Cookies set to use on server
        const maxAge = 30 * 24 * 60 * 60; // 1 month
        document.cookie = `sa-access-token=${session.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
        document.cookie = `sa-refresh-token=${session.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
      }

      if (session?.access_token !== accessToken) {
        router.refresh();
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [accessToken, router, supabaseClient.auth]);

  return null;
}
